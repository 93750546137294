<template>
  <div v-if="bootstrapped" class="h-screen flex flex-col items-center justify-start" style="background-color: #faf9ff">
    <div class="z-0 flex w-full">
      <div
        class="left-bar absolute z-1 min-h-full max-h-screen overflow-auto left-0 top-0 bottom-0 hidden lg:flex lg:flex-col transition-width ease-in duration-150 py-4 px-4"
        :class="{ 'lg:w-sidebar--opened': leftMenuOpened, 'lg:w-sidebar': !leftMenuOpened }"
      >
        <Navigation v-model:open="leftMenuOpened" :navItems="navItems" :logo="navigationLogo" />
      </div>
      <div
        class="top-bar fixed z-10 top-0 right-0 transition-all ease-in duration-150 left-0"
        :class="{ 'lg:left-top-bar--opened': leftMenuOpened, 'lg:left-top-bar': !leftMenuOpened }"
        style="background-color: #f5f3fe"
      >
        <div class="w-full pt-3 pb-3 absolute flex items-center z-0 px-6 min-h-16" style="background-color: #f6f4fe">
          <IconButton icon="menu" class="lg:hidden text-primary -ml-4" @click="subMenuOpened = !subMenuOpened" />
          <div class="text-17 flex justify-center items-center">
            <span class="text-14 lg:text-20 text-primary font-600"> {{ header }} </span>
            <span v-if="false" class="px-3 text-primary font-600">|</span>
            <div class="ml-3">
              <component :is="headerComponent" />
            </div>
          </div>
          <div class="flex ml-auto items-center">
            <Icon name="faq" class="w-5 h-5 cursor-pointer mr-4" @click="SET_SHOW_SIDE_PANEL(true)" />
            <Icon name="notification" class="w-6 h-6 cursor-pointer" @click="showNotificationPopup = true" v-outside-click="closeNotificationPopup" />

            <Notifications v-if="showNotificationPopup" />
          </div>
        </div>
      </div>
      <div class="main-content pt-14 lg:pt-14 w-full flex flex-col h-screen" :class="{ 'lg:pl-main-content--opened': leftMenuOpened, 'lg:pl-main-content': !leftMenuOpened }">
        <div class="flex-grow flex flex-row overflow-auto relative max-lg:z-1">
          <div class="bg-black opacity-25 absolute inset-0 z-1 lg:hidden" :class="{ hidden: !subMenuOpened }" @click="subMenuOpened = false"></div>
          <div
            class="w-280 lg:w-280 flex-shrink-0 lg:-ml-4 max-lg:absolute max-lg:z-1 max-lg:min-h-full max-lg:shadow-xl transition-all ease-in duration-200"
            :class="{ 'max-lg:left-0': subMenuOpened, 'max-lg:-left-[280px]': !subMenuOpened }"
            style="background-color: rgb(250, 249, 255)"
          >
            <ul class="mt-7 pl-4 lg:pl-7 pr-4 text-15 select-none">
              <template v-for="(item, index) in userMenuItems">
                <li v-if="item.children" :key="`c-${index}`" class="my-2 cursor-pointer">
                  <div
                    class="w-full py-2 cursor-pointer pl-5 rounded-8 hover:bg-gray-200 flex justify-between"
                    :class="{ 'bg-gray-300 text-black hover:bg-gray-200': isLinkActive(item) || item.open }"
                    @click.stop="item.open = !item.open"
                  >
                    {{ item.label }}
                    <Icon v-if="!item.alwaysOpen" name="expand_more" class="w-6 h-6 mr-2 transition-all ease-in duration-200" :class="{ 'rotate-180 transform': item.open }" />
                  </div>
                  <div
                    class="w-full overflow-hidden relative transition-all duration-300 ease-in-out max-h-0"
                    :class="{ 'max-h-500': item.open || item.alwaysOpen, 'opacity-0': !item.open, 'opacity-100': item.open || item.alwaysOpen }"
                  >
                    <div class="w-full transition-all duration-300 ease-in-out" :style="{ transform: item.open || item.alwaysOpen ? 'translateY(0)' : 'translateY(-100%)' }">
                      <ul>
                        <li
                          v-for="(childItem, childIndex) in item.children"
                          :key="childIndex"
                          :class="{ 'bg-primary text-white hover:bg-primary hover:bg-opacity-[90%]': isLinkActive(childItem) }"
                          class="pl-8 my-1 py-2 cursor-pointer rounded-8 hover:bg-gray-200 block"
                          @click="goTo(childItem)"
                        >
                          {{ childItem.label }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  v-else
                  :key="index"
                  :class="{ 'bg-primary text-white hover:bg-primary hover:bg-opacity-[90%]': isLinkActive(item) }"
                  class="pl-5 my-1 py-2 cursor-pointer hover:bg-gray-200 rounded-8"
                  @click="goTo(item)"
                >
                  {{ item.label }}
                </li>
              </template>
            </ul>
          </div>
          <div class="w-full flex-grow overflow-hidden flex flex-col md:pl-3 max-lg:z-0">
            <div class="w-full flex-grow overflow-hidden rounded-tl-20 mt-3 lg:mt-6 bg-white shadow-analysisCard px-2 py-2 lg:py-10 lg:pb-0 lg:pl-10" style="background-color: #fcfcff">
              <div class="w-full h-full overflow-auto">
                <router-view />
                <slot />
              </div>
            </div>
          </div>
        </div>

        <div class="h-14 inline lg:hidden z-0 bottom-navigation">
          <BottomNavigation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { FEATURES } from '@/constants';
import OrganisationSelect from '@/components/datasource/OrganisationSelect.vue';

export default {
  name: 'SideLayout',
  components: { OrganisationSelect },
  data() {
    return {
      leftMenuOpened: false,
      subMenuOpened: false,
      showNotificationPopup: false,
      selectedNavItem: '',
      menuItems: [],
      navItems: [
        { label: 'navigation.dashboard', to: '/', icon: 'dashboard' },
        { label: 'navigation.conversations', to: '/conversation', icon: 'conversations', restrictTo: FEATURES.CONVERSATIONS },
        { label: 'navigation.collections', to: '/collections', icon: 'collections', restrictTo: FEATURES.COLLECTIONS },
        { label: 'navigation.settings', to: '/user/settings', icon: 'settings', restrictTo: FEATURES.SETTINGS },
        { label: 'navigation.logout', to: '/logout', icon: 'logout' },
      ],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(['bootstrapped', 'user', 'features', 'locale']),
    ...mapGetters(['getProjectLogos']),
    navigationLogo() {
      return this.getProjectLogos.NAVIGATION;
    },
    headerComponent() {
      // const [firstMatched] = this.$route.matched;

      // if (firstMatched) {
      // if (firstMatched.meta.header === 'no_code') {
      //   return NoCodeHeader;
      // }
      // }
      return null;
    },
    header() {
      const [firstMatched] = this.$route.matched;

      if (firstMatched) {
        if (firstMatched.meta.header === 'datasources') {
          return this.$t('navigation.data_source_management');
        }
        if (firstMatched.meta.header === 'no_code') {
          return this.$t('navigation.projects');
        }
      }

      return this.$t('navigation.settings');
    },
    userMenuItems() {
      return this.menuItems.filter((item) => {
        if (item.restrictTo) {
          return this.features.includes(item.restrictTo);
        }
        return true;
      });
    },
  },
  methods: {
    ...mapMutations(['SET_SHOW_SIDE_PANEL']),
    closeNotificationPopup() {
      this.showNotificationPopup = false;
    },
    initialize() {
      this.subMenuOpened = false;
      this.createMenuItems();
      this.menuItems.forEach((item) => {
        if (item.collapsable === false) {
          item.open = true;
        } else {
          item.open = this.isLinkActive(item);
        }
      });

      this.menuItems.forEach((item) => {
        if (item.to === this.$route.fullPath) {
          this.selectedNavItem = item.label;
        }
        if (item.children) {
          item.children.forEach((child) => {
            if (child.to === this.$route.fullPath) {
              this.selectedNavItem = child.label;
            }
          });
        }
      });
    },
    createMenuItems() {
      let menuItems = [];

      menuItems = [
        { label: this.$t('navigation.personal_settings'), to: '/user/settings', restrictTo: FEATURES.SETTINGS_PROFILE },
        { label: this.$t('navigation.security'), to: '/user/security', restrictTo: FEATURES.SETTINGS_PROFILE },
        // { label: this.$t('navigation.subscription'), to: '/user/subscriptions', restrictTo: FEATURES.SETTINGS_SUBSCRIPTION },
        { label: this.$t('navigation.data_sources'), to: '/user/datasources', restrictTo: FEATURES.SETTINGS_DATASOURCE },
      ];

      this.menuItems = menuItems;
    },
    goTo(item) {
      if (typeof item.to === 'object') {
        this.$router.push(item.to);
        return;
      }
      if (Object.keys(this.$route.params).length) {
        this.$router.push({ name: item.name, params: { id: this.$route.params.id } });
        return;
      }
      this.$router.push({ path: `${item.to}` });
    },
    isLinkActive(item) {
      let active = false;
      if (this.$route.matched.some((route) => route.path === item.to)) {
        active = true;
      } else if (item.name === this.$route.name || item?.to?.name === this.$route.name) {
        active = true;
      } else if (item.to === this.$route.fullPath) {
        active = true;
      } else if (this.$route.fullPath.startsWith(item.to)) {
        active = true;
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (this.$route.matched.filter((r) => r.name).some((route) => route.name === child.name || route.name === child?.to?.name)) {
            active = true;
          } else if (child.to === this.$route.fullPath) {
            active = true;
          }
        });
      }
      return active;
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.initialize();
      },
    },
    '$route.hash': {
      handler() {
        this.initialize();
      },
    },
    locale() {
      this.initialize();
    },
  },
};
</script>
