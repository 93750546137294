import { del, get, post, put } from './fetch';

let store;

export function setStore(vuexStore) {
  store = vuexStore;
}

async function ResponseAdapter(response) {
  switch (response.status) {
    case 200:
    case 400:
    case 401:
    case 403:
      try {
        let currentUser = response.headers.get('Current-User');
        if (currentUser) {
          currentUser = JSON.parse(currentUser);
        }
        const parsedResponse = await response.json();
        return { status: response.status, data: parsedResponse, currentUser };
      } catch (e) {
        return {
          status: response.status,
          message: e.message,
        };
      } finally {
        if (response.status === 401) {
          store.dispatch('logout');
        }
      }
    case 500:
      try {
        const parsedResponse = await response.json();
        return {
          status: response.status,
          data: {
            error: true,
            message: parsedResponse.message,
          },
        };
      } catch (e) {
        return {
          status: response.status,
          data: {
            error: true,
            message: e.message,
          },
        };
      }
    default:
      break;
  }

  return {
    status: response.status,
    message: response?.data?.message || 'UNKNOWN_DATA',
  };
}

export async function apiPostLogout() {
  const response = await post('/auth/logout');
  return ResponseAdapter(response);
}
export async function apiGetAuthProviders() {
  const response = await get('/auth/providers');
  return ResponseAdapter(response);
}
export async function apiDeleteAccount() {
  const response = await del('/api/v1/auth/account');
  return ResponseAdapter(response);
}
export async function apiPostChangePassword({ password }) {
  const response = await post('/api/v1/auth/changepassword', { password });
  return ResponseAdapter(response);
}
export async function apiPostResetPassword({ email, token, password }) {
  const response = await post('/auth/passwordreset', { email, token, password });
  return ResponseAdapter(response);
}
export async function apiPostForgotPassword({ email, token, local = false }) {
  if (window.location.host === 'local.wobsy.com') {
    local = true;
  }
  const response = await post('/auth/forgotpassword', { email, token, local });
  return ResponseAdapter(response);
}
export async function apiPostLoginWithPassword({ email, password, token }) {
  const payload = { email, password, token };
  return post('/auth/loginwithpassword', payload);
}

export async function apiGetTenant() {
  const response = await get('/auth/tenant');
  return ResponseAdapter(response);
}
export async function apiPostSendSigninEmailLink({ email, token }) {
  const payload = { email, token };
  if (window.location.host === 'local.wobsy.com') {
    payload.local = true;
  }
  const response = await post('/auth/loginwithemail', payload);
  return ResponseAdapter(response);
}
export async function apiPostRegister({ password, name, timezone, picture, country, organisation, position, language }) {
  const payload = { password, name, timezone, picture, country, organisation, position, language };
  const response = await post('/auth/register', payload);
  return ResponseAdapter(response);
}

export async function apiGetTenantProjects() {
  const response = await get('/auth/tenant/projects');
  return ResponseAdapter(response);
}

export async function apiPostAdminDatasourceRevoke({ datasource_id, type }) {
  const response = await post('/api/v1/datasource/revoke', { datasource_id, type });
  return ResponseAdapter(response);
}
export async function apiPostDatasourceAccount({ type, datasource_id, account }) {
  const response = await post('/api/v1/datasource/account', { type, datasource_id, account });
  return ResponseAdapter(response);
}
export async function apiDeleteDatasourceAccount({ type, datasource_id, account_id }) {
  const response = await post('/api/v1/datasource/account/remove', { type, datasource_id, account_id });
  return ResponseAdapter(response);
}
export async function apiGetDatasourceAccounts(type, datasource_id) {
  const response = await get(`/api/v1/datasource/accounts?type=${type}&datasource_id=${datasource_id}`);
  return ResponseAdapter(response);
}

export async function apiPostCollectionsQuery({ type, datasource_id, account_id, id, collection_id }) {
  const res = await post('/api/v1/collection/query', { type, datasource_id, account_id, collection_id, id });
  return ResponseAdapter(res);
}
export async function apiPostCollectionPreview({ phrase, type, datasource_id, account_id, date_range }) {
  const res = await post('/api/v1/collection/preview', { phrase, type, datasource_id, account_id, date_range });
  return ResponseAdapter(res);
}

export async function apiPostDatasourceDefaults(payload) {
  const response = await post(`/api/v1/datasource/defaults`, payload);
  return ResponseAdapter(response);
}

export async function apiGetCollection(collection_id) {
  const response = await get(`/api/v1/collection?collection=${collection_id}`);
  return ResponseAdapter(response);
}

export async function apiPutProfile(payload) {
  const response = await put('/api/v1/tenant/profile', payload);
  return ResponseAdapter(response);
}
export async function apiPutFlag({ key, value }) {
  const response = await put(`/api/v1/flag`, { key, value });
  return ResponseAdapter(response);
}

export async function apiSetActiveAccount(payload) {
  const response = await post('/api/v1/conversations/account/select', payload);
  return ResponseAdapter(response);
}

export async function apiGetSuggestions({ type, model_id }) {
  const response = await get(`/api/v1/conversations/suggestions?type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiGetSelectedAccount() {
  const res = await get('/api/v1/selectedaccount');
  return ResponseAdapter(res);
}

export async function apiGetConfig() {
  const res = await get('/auth/config');
  return ResponseAdapter(res);
}

export async function apiGet(path, queryParams) {
  const paramStr = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : null;
  const res = await get(`${path}${paramStr}`);
  return ResponseAdapter(res);
}
export async function apiPost(path, data) {
  const res = await post(path, data);
  return ResponseAdapter(res);
}
export async function apiPut(path, data) {
  const res = await put(path, data);
  return ResponseAdapter(res);
}
export async function apiDel(path, queryParams) {
  const paramStr = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : null;
  const res = await get(`${path}${paramStr}`);
  return ResponseAdapter(res);
}
