<template>
  <DefaultNoHeaderLayout class="onboarding">
    <div class="flex flex-col max-w-screen-xxl h-screen mx-auto">
      <div class="h-25 py-6 px-10 flex justify-between items-center">
        <img :src="logo" :alt="appName" class="h-11" />
        <Icon name="logout" class="text-gray-600 w-5 h-4 cursor-pointer" :title="$t('logout')" @click="onLogoutClick" />
      </div>
      <div v-if="bootstrapped" class="grid px-1 sm:px-4 lg:px-10 mx-auto w-full">
        <FadeTransition enterActiveClass="transition-all ease duration-500 delay-500" leaveActiveClass="transition-all ease duration-500">
          <Register v-if="step === 0" class="col-start-1 col-end-1 row-start-1 row-end-1" @next="complete" />
        </FadeTransition>
      </div>
    </div>
  </DefaultNoHeaderLayout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal';
import Register from '@/components/onboarding/Register.vue';
import { SUBSCRIPTION_STATES } from '@/constants';

export default {
  name: 'Onboarding',
  components: { Register },
  data() {
    return {
      step: 0,
      height: 0,
    };
  },
  computed: {
    ...mapState(['bootstrapped', 'locale', 'user', 'subscription', 'config']),
    ...mapGetters(['getRegistrationComplete', 'getProjectLogos']),
    appName() {
      return this.config?.brand_name || this.config?.name || '';
    },
    logo() {
      return this.getProjectLogos.PROJECT;
    },
  },
  mounted() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  methods: {
    ...mapActions(['showToastMessage', 'logout']),
    onLogoutClick() {
      this.$showModal(DeleteModal, { title: this.$t('logout'), subtitle: this.$t('confirm_logout_message'), confirm: this.$t('logout'), onConfirm: () => this.logout({ clearIdb: true }) });
    },
    skip() {
      this.complete();
    },
    async complete() {
      this.$router.replace('/');
    },
  },
  watch: {
    bootstrapped: {
      handler() {
        if (this.bootstrapped) {
          if (this.getRegistrationComplete) {
            this.complete();
            return;
          }

          if (this.subscription.status === SUBSCRIPTION_STATES.ACTIVE && this.subscription.plan !== 'FREE') {
            this.complete();
          }

          if (this.getRegistrationComplete) {
            this.$router.push('/');
          }
        }
      },
      immediate: true,
    },
    step() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
.onboarding {
  background-image: url('@/assets/onboarding/onboarding-bg.png');
  background-repeat: no-repeat;
  background-position: 0 94%;
  background-size: auto 61%;
}
</style>
