<template>
  <div
    v-if="result"
    ref="analysiscard"
    class="analysis-card flex flex-col items-left text-14 text-center"
    style="min-height: 250px"
    :style="{ height: !renderCard ? `${cardHeight}px` : '' }"
    :class="{ 'max-h-350': !exportMode, 'h-full': exportMode }"
  >
    <div v-if="result.title" class="py-2 flex items-center border-b border-solid border-purple-200 w-full">
      <div v-html="result.title" class="w-full text-14 px-4"></div>
    </div>
    <div v-if="renderCard" class="flex-grow flex relative h-[294px]" :class="{ 'overflow-hidden': showInsightsPanel && !exportMode, 'overflow-auto': !showInsightsPanel && !exportMode }">
      <AnalysisCardSwiper
        ref="cardSwiper"
        :result="result"
        :showExportButton="showExportButton"
        :exportMode="exportMode"
        :exportChartType="exportChartType"
        :datasourceType="datasourceType"
        :selectedChartType="selectedChartType"
        :showCardText="showCardText"
        @slideChange="onSlideChange"
      />
      <CardInsights class="z-1" :datasource-type="datasourceType" :show="showInsightsPanel" :insights="insights" />
    </div>
    <div v-if="renderCard" class="h-12 sm:h-14 flex-shrink-0 flex flex-between items-center flex-wrap-reverse border-t border-solid border-purple-200 w-full">
      <div
        class="max-xs:w-1/3 w-1/2 sm:w-[140px] h-12 sm:h-14 flex flex-column pl-5 items-center border-r border-solid border-purple-200 cursor-pointer"
        v-if="result.insights"
        @click="toggleInsightsPanel"
      >
        <div class="text-gray-900 w-full text-15 font-700 select-none flex flex-row justify-center">
          <span class="max-sm:text-12">{{ $t('insights') }}</span>
        </div>
        <Icon name="expand_more" class="w-7 ml-5 mr-3 transform" :class="{ 'rotate-180': showInsightsPanel }" />
      </div>
      <div class="flex-grow w-1/2 md:w-2/3 flex justify-end mb-0 max-md:pr-4">
        <PillButton
          v-if="result.text && selectedChartType !== 'text' && selectedChartType !== 'none' && !showInsightsPanel"
          :text="$t('summary')"
          :icon="!showCardText ? 'speaker_notes' : 'speaker_notes_off'"
          outlined
          icon-class="text-primary text-opacity-70 max-md:!mr-0"
          text-class="max-md:hidden"
          class="mr-1 xs:mr-3 max-md:hidden"
          :disabled="showInsightsPanel"
          @click="toggleCardText"
        />
        <PillButton
          v-if="supportedChartTypes.length > 1"
          :text="$t('conversation.chart_types')"
          icon="insights"
          outlined
          icon-class="text-primary text-opacity-70 max-md:!mr-0"
          text-class="max-md:hidden"
          class="mr-1 xs:mr-3"
          @click="toggleGraph"
        />
        <PillButton
          v-if="supportedChartTypes.length && showExportButton && !exportMode"
          tertiary
          icon="expand"
          :text="$t('conversation.expand')"
          class="mr-1 md:mr-5 lg:mr-6"
          icon-class="max-md:!mr-0 -ml-1"
          text-class="max-md:hidden"
          @click="$emit('export', { showCardText, selectedChartType, showInsightsPanel })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardInsights from '@/components/conversation/CardInsights';
import AnalysisCardSwiper from '@/components/conversation/AnalysisCardSwiper.vue';

export default {
  name: 'AnalysisCard',
  components: { AnalysisCardSwiper, CardInsights },
  props: {
    result: Object,
    showExportButton: { type: Boolean, default: true },
    exportMode: Boolean,
    exportChartType: String,
    exportShowCardText: { type: Boolean, default: true },
    exportShowInsights: { type: Boolean, default: false },
    datasourceType: String,
  },
  data() {
    return {
      selectedChartType: '',
      showInsightsPanel: false,
      text: '',
      showCardText: true,
      renderCard: false,
      observer: null,
      cardHeight: 250,
    };
  },
  created() {
    this.selectedChartType = this.exportChartType || this.supportedChartTypes[0];
    this.showCardText = this.exportShowCardText;
    this.showInsightsPanel = this.exportShowInsights;
  },
  mounted() {
    const options = { threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0] };
    this.observer = new IntersectionObserver(([entry]) => {
      const visiblePct = `${Math.floor(entry.intersectionRatio * 100)}`;
      this.renderCard = visiblePct !== '0';
    }, options);

    this.observer.observe(this.$refs.analysiscard);

    const { height } = this.$refs.analysiscard.getBoundingClientRect();
    this.cardHeight = height;
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
  computed: {
    ...mapState(['availableDataSources']),
    insights() {
      return this.result.insights || [];
    },
    supportedChartTypes() {
      const types = this.result?.chart?.map((c) => c.chart.type.toLowerCase()) || [];
      if (this.result?.image?.url) {
        types.push('image');
      }
      if (this.result.table) {
        types.push('table');
      }
      if (window.innerWidth < 996 && this.result.text) {
        types.push('text');
      }
      return types;
    },
    selectedChartIndex() {
      const chartType = this.selectedChartType;
      return this.supportedChartTypes.indexOf(chartType) === -1 ? 0 : this.supportedChartTypes.indexOf(chartType);
    },
  },
  methods: {
    onSlideChange(e) {
      this.selectedChartType = this.supportedChartTypes[e.realIndex];
      this.$emit('displayTypeChange', this.selectedChartType);
    },
    toggleCardText() {
      this.showCardText = !this.showCardText;
    },
    toggleGraph() {
      if (this.showInsightsPanel) {
        this.showInsightsPanel = false;
        return;
      }
      if (this.supportedChartTypes.length <= 1) {
        return;
      }
      if (this.supportedChartTypes.length > this.selectedChartIndex + 1) {
        const type = this.supportedChartTypes[this.selectedChartIndex + 1];
        this.changeChartType(type);
      } else {
        const type = this.supportedChartTypes[0];
        this.changeChartType(type);
      }
    },
    changeChartType(type) {
      this.$refs.cardSwiper.$refs.swiper.slideNext();
      this.selectedChartType = type;
      this.$emit('displayTypeChange', this.selectedChartType);
    },
    toggleInsightsPanel() {
      this.showInsightsPanel = !this.showInsightsPanel;
      if (this.showInsightsPanel) {
        this.$emit('displayTypeChange', 'insights');
      } else {
        this.$emit('displayTypeChange', this.selectedChartType);
      }
    },
  },
  watch: {
    renderCard() {
      if (!this.renderCard) {
        const { height } = this.$refs.analysiscard.getBoundingClientRect();
        this.cardHeight = height;
      } else {
        this.cardHeight = null;
      }
    },
  },
};
</script>
<style lang="scss">
.analysis-card {
  .datatable {
    height: 100%;
    > div:first-child {
      height: 100%;
      > table {
        height: 100%;
      }
    }
  }
}
</style>
