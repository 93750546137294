import { createApp } from 'vue';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import FloatingVue from 'floating-vue';
import { createVfm } from 'vue-final-modal';
import VueApexCharts from 'vue3-apexcharts';
import draggable from 'vuedraggable';
import 'vue-select/dist/vue-select.css';
import 'floating-vue/dist/style.css';
import App from '@/components/App.vue';
import store from '@/plugins/store';
import router from '@/plugins/router';
import modalPlugin from '@/plugins/modal';
import '@/assets/css/tailwind.css';
import '@/assets/css/theme.css';
import '@/assets/css/vue-select.css';
import '@/assets/css/tooltip.css';
import i18n from '@/plugins/i18n';
import DefaultLayout from '@/components/layouts/Default';
import DefaultNoHeader from '@/components/layouts/DefaultNoHeader';

import { setStore } from '@/helpers/api';
import CompactAnalysisCard from '@/components/conversation/CompactAnalysisCard';

import OutsideClickDirective from '@shared/directives/outsideClickDirective';

import vSelect from '@shared/components/ui/VSelectWrapper';
import LinkButton from '@shared/components/ui/LinkButton';
import Button from '@shared/components/ui/Button';
import PillButton from '@shared/components/ui/PillButton';
import IconToggleButton from '@shared/components/ui/IconToggleButton';
import Badge from '@shared/components/ui/Badge';
import Modal from '@shared/components/ui/Modal';
import FullScreenModal from '@shared/components/ui/FullScreenModal';
import Avatar from '@shared/components/ui/Avatar';
import Input from '@shared/components/ui/Input';
import FormInput from '@shared/components/ui/FormInput';
import FormTextarea from '@shared/components/ui/FormTextarea';
import FormPassword from '@shared/components/ui/FormPassword';
import FormSelect from '@shared/components/ui/FormSelect';
import Textarea from '@shared/components/ui/Textarea';
import Toast from '@shared/components/ui/Toast';
import Icon from '@shared/components/ui/Icon';
import Card from '@shared/components/ui/Card';
import MultiSelect from '@shared/components/ui/MultiSelect';
import Divider from '@shared/components/ui/Divider';
import ListCard from '@shared/components/ui/ListCard';
import Spinner from '@shared/components/ui/Spinner';
import RadioButton from '@shared/components/ui/RadioButton';
import Checkbox from '@shared/components/ui/Checkbox';
import ButtonToggle from '@shared/components/ui/ButtonToggle';
import CollapseMenu from '@shared/components/ui/CollapseMenu';
import CollapseMenuBorderless from '@shared/components/ui/CollapseMenuBorderless';
import CollapseCard from '@shared/components/ui/CollapseCard';
import ContentCard from '@shared/components/ui/ContentCard';
import Toggle from '@shared/components/ui/Toggle';
import IconButton from '@shared/components/ui/IconButton';
import DataTable from '@shared/components/ui/DataTable';
import ValidationObserver from '@shared/components/ui/ValidationObserver';
import Notifications from '@shared/components/ui/Notifications';
import Quota from '@shared/components/ui/Quota';
import Navigation from '@shared/components/ui/Navigation';
import BottomNavigation from '@shared/components/ui/BottomNavigation';
import ImageCropper from '@shared/components/ui/ImageCropper';
import Tabs from '@shared/components/ui/Tabs';
import MaterialInput from '@shared/components/ui/MaterialInput';
import MaterialSelect from '@shared/components/ui/MaterialSelect';
import FormLabel from '@shared/components/ui/FormLabel';
import Alert from '@shared/components/ui/Alert';
import ButtonDropdown from '@shared/components/ui/ButtonDropdown';
import Swiper from '@shared/components/ui/Swiper';
import SwiperSlide from '@shared/components/ui/SwiperSlide';
import IntersectionObserver from '@shared/components/ui/IntersectionObserver';
import Prompt from '@shared/components/ui/Prompt';
import AccordionItem from '@shared/components/ui/AccordionItem';
import Accordion from '@shared/components/ui/Accordion';
import ExpressionRenderer from '@shared/components/ui/ExpressionRenderer';
import PhraseEditor from '@shared/components/ui/PhraseEditor';
import ProjectSelectDropdown from '@shared/components/ui/ProjectSelectDropdown.vue';
import FadeTransition from '@shared/components/transitions/FadeTransition.vue';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';

import '@/assets/css/vfm.css';

setStore(store);
router.setStore(store);

const vfm = createVfm();

dayjs.extend(isToday);

const app = createApp(App);
app.use(modalPlugin, { appInstance: app });

app.directive('outside-click', OutsideClickDirective);

app.component('v-select', vSelect);
app.component('apexchart', window.VueApexCharts);
app.component('DefaultLayout', DefaultLayout);
app.component('DefaultNoHeaderLayout', DefaultNoHeader);
app.component('PillButton', PillButton);
app.component('IconToggleButton', IconToggleButton);
app.component('Badge', Badge);
app.component('Avatar', Avatar);
app.component('Input', Input);
app.component('FormInput', FormInput);
app.component('FormTextarea', FormTextarea);
app.component('FormPassword', FormPassword);
app.component('FormSelect', FormSelect);
app.component('Textarea', Textarea);
app.component('Button', Button);
app.component('ButtonToggle', ButtonToggle);
app.component('MultiSelect', MultiSelect);
app.component('LinkButton', LinkButton);
app.component('Modal', Modal);
app.component('FullScreenModal', FullScreenModal);
app.component('Toast', Toast);
app.component('Icon', Icon);
app.component('IconButton', IconButton);
app.component('Card', Card);
app.component('Divider', Divider);
app.component('ListCard', ListCard);
app.component('Spinner', Spinner);
app.component('RadioButton', RadioButton);
app.component('Checkbox', Checkbox);
app.component('CollapseMenu', CollapseMenu);
app.component('CollapseMenuBorderless', CollapseMenuBorderless);
app.component('CollapseCard', CollapseCard);
app.component('ContentCard', ContentCard);
app.component('CompactAnalysisCard', CompactAnalysisCard);
app.component('DataTable', DataTable);
app.component('Toggle', Toggle);
app.component('Draggable', draggable);
app.component('ValidationObserver', ValidationObserver);
app.component('Notifications', Notifications);
app.component('Quota', Quota);
app.component('Navigation', Navigation);
app.component('BottomNavigation', BottomNavigation);
app.component('ImageCropper', ImageCropper);
app.component('Tabs', Tabs);
app.component('MaterialInput', MaterialInput);
app.component('MaterialSelect', MaterialSelect);
app.component('FormLabel', FormLabel);
app.component('Alert', Alert);
app.component('ButtonDropdown', ButtonDropdown);
app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);
app.component('IntersectionObserver', IntersectionObserver);
app.component('Prompt', Prompt);
app.component('AccordionItem', AccordionItem);
app.component('Accordion', Accordion);
app.component('ExpressionRenderer', ExpressionRenderer);
app.component('PhraseEditor', PhraseEditor);
app.component('ProjectSelectDropdown', ProjectSelectDropdown);

app.component('FadeTransition', FadeTransition);
app.component('DeleteModal', DeleteModal);

app.use(store).use(router).use(i18n).use(vfm).mount('#app');

app.use(FloatingVue);
app.use(VueApexCharts);
