<template>
  <ValidationObserver v-slot="{ validate, errors }">
    <div class="flex flex-grow w-full max-lg:flex-wrap">
      <div class="w-full lg:w-4/12">
        <div class="text-4xl sm:text-5xl md:text-6xl pt-8 lg:pt-18 text-primary md:text-center md:pr-15 sm:text-center px-3">{{ $t('welcome') }}</div>
      </div>
      <div class="w-full lg:w-8/12">
        <div class="grid pt-0 sm:pt-8 lg:pt-18">
          <div class="col-start-1 col-end-2 row-start-1 row-end-2 pb-5">
            <div class="font-600 my-5 md:my-0 text-xl sm:text-3xl md:text-4xl px-3">{{ $t('onboarding.message_1') }}</div>

            <div class="mt-6 text-16 px-3">{{ $t('onboarding.message_2') }}</div>

            <div class="mt-12">
              <div class="w-full">
                <MaterialInput class="mt-4" v-model="name" name="name" rules="required" :error="errors['name']" :label="$t('name')" />
              </div>
              <div class="flex flex-wrap mt-3">
                <div class="w-full sm:w-1/2">
                  <MaterialInput
                    class="mt-4 sm:mr-8"
                    v-model="password"
                    name="password"
                    :error="errors['password']"
                    :rules="[{ validate: passwordValidation }]"
                    type="password"
                    :label="$t('onboarding.password')"
                    spellcheck="false"
                    @show-password="showRepeatPassword = !$event"
                  />
                </div>
                <div v-if="showRepeatPassword" class="w-full sm:w-1/2">
                  <MaterialInput
                    class="mt-4"
                    name="repeatPassword"
                    :error="errors['repeatPassword']"
                    :rules="[{ validate: repeatPasswordValidation }]"
                    :showPasswordEye="false"
                    v-model="repeatPassword"
                    type="password"
                    :label="$t('onboarding.repeat_password')"
                    spellcheck="false"
                  />
                </div>
              </div>
              <div v-if="false" class="flex flex-wrap mt-3">
                <div class="w-full sm:w-1/2"><MaterialInput class="mt-4 md:mr-8" v-model="organisation" :label="$t('login.organisation')" spellcheck="false" /></div>
                <div class="w-full sm:w-1/2"><MaterialInput class="mt-4" v-model="position" :label="$t('login.position')" spellcheck="false" /></div>
              </div>
              <div class="flex flex-wrap mt-3">
                <div class="w-full sm:w-1/2">
                  <MaterialSelect
                    v-model="country"
                    name="country"
                    :error="errors['country']"
                    rules="required"
                    searchable
                    class="mt-4 md:mr-8"
                    :items="countries"
                    :label="$t('login.country')"
                    spellcheck="false"
                  />
                </div>
                <div class="w-full sm:w-1/2">
                  <MaterialSelect
                    class="mt-4"
                    v-model="timezone"
                    name="timezone"
                    rules="required"
                    :error="errors['timezone']"
                    searchable
                    :items="timezones"
                    :label="$t('timezone')"
                    spellcheck="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between px-3 py-3">
      <div class="max-w-screen-xxl mx-auto w-full flex justify-end">
        <PillButton tertiary :loading="loading" :disabled="loading" :text="$t('login.continue')" class="ml-auto" @click="next(validate)"></PillButton>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { COUNTRIES, TIMEZONES } from '@/constants';
import { apiPostRegister } from '@/helpers/api';
import * as validators from '@shared/helpers/validators';

export default {
  name: 'Register',
  data() {
    return {
      showRepeatPassword: true,
      password: '',
      repeatPassword: '',
      name: '',
      timezone: '',
      country: '',
      organisation: '',
      position: '',
      picture: '',
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.name })),
      loading: false,
    };
  },
  created() {
    if (this.user) {
      this.name = this.user.name;
      this.country = this.user.country;
      this.timezone = this.$store.state.timezone;
      this.organisation = this.user.organisation;
      this.position = this.user.position;
      this.picture = this.user.picture;
    }
  },
  computed: {
    ...mapState(['user', 'locale']),
    timezones() {
      return TIMEZONES.map((i) => ({ label: i, value: i }));
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    ...mapGetters(['getOnboardingComplete']),
    async next(validate) {
      const valid = await validate();
      if (valid.valid) {
        this.loading = true;
        const { name, password, timezone, picture, country, organisation, position, locale } = this;
        const payload = { name, password, timezone, picture, country, organisation, position, language: locale };
        try {
          const response = await apiPostRegister(payload);
          if (response.status === 200) {
            this.$emit('next');
          } else {
            this.showToastMessage({ title: response.data.message || this.$t('onboarding.failed_to_register'), type: 'error' });
          }
        } catch (e) {
          this.showToastMessage({ title: this.$t('onboarding.failed_to_register'), type: 'error' });
        }
        this.loading = false;
      }
    },
    arePasswordsEqual() {
      return validators.same(this.password, this.repeatPassword) || this.$t('login.passwords_must_be_same');
    },
    passwordValidation() {
      if (this.password || this.repeatPassword) {
        if (!validators.password(this.password)) {
          return this.$t('validation.password');
        }
        if (this.showRepeatPassword) {
          return this.arePasswordsEqual();
        }
      }
      return true;
    },
    repeatPasswordValidation() {
      if (!this.showRepeatPassword) {
        return true;
      }
      if (this.password) {
        if (!validators.password(this.password)) {
          return this.$t('validation.password');
        }
        if (this.showRepeatPassword) {
          return this.arePasswordsEqual();
        }
      }
      return true;
    },
  },
};
</script>
